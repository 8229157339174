import React, { useEffect, useState } from "react";
import bluredBackgroundImage from "../../assets/images/adults-background-blured.png";
import backgroundImage from "../../assets/images/adults-background.png";
import backButtonLogo from "../../assets/images/back-button.svg";
import LanguageSelect from "../LanguageSelect/LanguageSelect";
import logo from "../../assets/images/adults-logo.png";
import Lottie from "react-lottie";
import failedLottie from "../../assets/lotties/failed.json";
import Scan from "../../assets/lotties/Scan.json";
import cs from "classnames";
import bouncerLogo from "../../assets/images/bouncer-icon.svg";
import successLottie from "../../assets/lotties/success.json";
import jwtDecode from "jwt-decode";
import { useTranslation } from "react-i18next";
import axios from "axios";

import "./Home.scss";
import { useResponsiveDimensions } from "../../helpers/useResponsiveDimensions";

const Home = () => {
  const [responseState, setResponseState] = useState("");
  const [isMoreThanEighteen, setIsMoreThanEighteen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMessageActive, setIsMessageActive] = useState(false);
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const isMobile = useResponsiveDimensions().isMobile;

  let params = new URLSearchParams(window.location.search);

  window.onload = () => {
    window.scrollTo({ top: 1000, behavior: "smooth" });
  };

  useEffect(() => {
    window.scrollTo({ top: 1000, behavior: "smooth" });
  });

  useEffect(() => {
    if (params.has("results_token")) {
      let token = params.get("results_token");
      if (token != null) {
        let decodeToken = jwtDecode(token);
        // @ts-ignore
        if (decodeToken?.verified === true) {
          setIsMoreThanEighteen(true);
          setIsMessageActive(true);
          setTimeout(() => {
            setIsMessageActive(false);
          }, 3000);
        }
        // @ts-ignore
        else {
          setIsMoreThanEighteen(false);
          setResponseState("failed");
        }
      }
    }
  }, [location.pathname]);

  const verifyWithBouncer = async () => {
    try {
      setIsLoading(true);
      const url = `${process.env.REACT_APP_API_URL}/generate_session_token`;
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Secure-Key": `${process.env.REACT_APP_SECURE_KEY}`,
          "Age-Target": "18",
          "Redirect-Url":`${window.location.origin}`,
          Scope: "email",
        },
      };
      const response = await axios.post(url, undefined, config);
      if (response.data.session_token) {
        setIsLoading(false);
        window.location.href = `${process.env.REACT_APP_BOUNCER_AGE_VERIFICATION_URL}/?session_token=${response.data.session_token}&lang=${lang}`;
      }
    } catch (e) {
      setIsMoreThanEighteen(false);
      setResponseState("failed");
      setIsLoading(false);
    }
  };

  return (
    <div className="Home">
      <div className="Home__container">
        {!isMoreThanEighteen ? (
          <img
            className="Home__container-blurBackgroundImage"
            src={bluredBackgroundImage}
          />
        ) : (
          <img
            className="Home__container-backgroundImage"
            src={backgroundImage}
          />
        )}
        {!isMoreThanEighteen ? (
          <div className="Container">
            <div className="Container__left">
              <div className="Container__left-head">
                <img
                  className="BackButton"
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    marginRight: "20px",
                  }}
                  src={backButtonLogo}
                  onClick={() => {
                    window.location.href = "https://www.bouncer.global/";
                  }}
                />
                <LanguageSelect />
              </div>
              <div className="Container__left-image">
                <img width={260} src={logo} alt="" className="logo" />
              </div>
              <div className="Container__left-title">
                adults.com {t("left.title")} Bouncer App
              </div>
              <div className="Container__left-content">
                {t("left.paragraph")}
              </div>
              <div className="Container__left-bottom-img">
                <svg
                  width="68"
                  height="68"
                  viewBox="0 0 68 68"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_423_1670)">
                    <path
                      d="M34 0C15.222 0 0 15.222 0 34C0 52.778 15.2218 68 34 68C52.7782 68 68 52.778 68 34C68 15.222 52.778 0 34 0ZM34 61.9986C18.537 61.9986 6.00142 49.463 6.00142 34C6.00142 18.537 18.537 6.00142 34 6.00142C49.463 6.00142 61.9986 18.537 61.9986 34C61.9986 49.463 49.463 61.9986 34 61.9986Z"
                      fill="#E41F2D"
                    />
                    <path
                      d="M60.1614 12.2832C63.8518 17.7215 66.008 24.2846 66.008 31.3517C66.008 50.1297 50.786 65.3517 32.008 65.3517C21.4875 65.3517 12.0834 60.5736 5.84766 53.0696C11.9628 62.0805 22.2902 67.9997 33.9998 67.9997C52.7778 67.9997 67.9998 52.7778 67.9998 33.9997C67.9998 25.7421 65.056 18.1721 60.1614 12.2832Z"
                      fill="#D3162D"
                    />
                    <path
                      d="M53.5167 26.5399H51.8515C51.6409 26.5399 51.4701 26.3691 51.4701 26.1585V24.4933C51.4701 23.2904 50.4857 22.2688 49.2829 22.2914C48.1165 22.3133 47.1778 23.2656 47.1778 24.4371V26.1586C47.1778 26.3693 47.007 26.5401 46.7964 26.5401H45.0289C43.826 26.5401 42.8044 27.5245 42.827 28.7272C42.8489 29.8936 43.8012 30.8323 44.9727 30.8323H46.7964C47.007 30.8323 47.1778 31.0031 47.1778 31.2137V32.9812C47.1778 34.1841 48.1622 35.2057 49.365 35.1831C50.5313 35.1612 51.4701 34.2089 51.4701 33.0374V31.2137C51.4701 31.0031 51.6409 30.8323 51.8515 30.8323H53.573C54.7446 30.8323 55.6968 29.8936 55.7187 28.7272C55.7412 27.5243 54.7196 26.5399 53.5167 26.5399Z"
                      fill="#5A5A5A"
                    />
                    <path
                      d="M18.4914 22.291H14.4403C13.2612 22.291 12.2599 23.2559 12.2821 24.4347C12.3035 25.5779 13.2369 26.498 14.3852 26.498H16.3879V43.5506C16.3879 44.7297 17.3527 45.731 18.5316 45.7088C19.6747 45.6874 20.5948 44.754 20.5948 43.6057V24.3945C20.5947 23.2328 19.6531 22.291 18.4914 22.291Z"
                      fill="#5A5A5A"
                    />
                    <path
                      d="M37.1978 32.1663C38.0777 31.0113 38.5601 29.538 38.4356 27.9513C38.2002 24.9515 35.7781 22.5359 32.7776 22.3091C29.1704 22.0363 26.1478 24.8941 26.1478 28.4447C26.1478 29.8424 26.617 31.1321 27.405 32.1664C25.613 33.6603 24.5106 35.955 24.6553 38.4958C24.882 42.4774 28.1971 45.6486 32.1847 45.7084C36.4611 45.7724 39.9605 42.3123 39.9605 38.0504C39.9602 35.6878 38.8845 33.5723 37.1978 32.1663ZM30.3707 28.6985C30.2091 27.427 31.2834 26.3527 32.555 26.5143C33.4217 26.6244 34.1216 27.3243 34.2317 28.191C34.3933 29.4626 33.3192 30.5371 32.0476 30.3754C31.1809 30.2652 30.481 29.5653 30.3707 28.6985ZM32.5828 41.491C30.4585 41.6604 28.6914 39.8933 28.8606 37.769C28.9939 36.0972 30.3481 34.743 32.0199 34.6097C34.1442 34.4404 35.9113 36.2073 35.7421 38.3318C35.6089 40.0034 34.2546 41.3578 32.5828 41.491Z"
                      fill="#5A5A5A"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_423_1670">
                      <rect width="68" height="68" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
            <div className="Container__right">
              <div className="Container__right-video">
                {responseState === "failed" ? (
                  <Lottie
                    style={{ width: "300px", height: "150px" }}
                    options={{
                      loop: false,
                      autoplay: true,
                      animationData: failedLottie,
                    }}
                  />
                ) : (
                  <Lottie
                    style={{ width: "300px", height: "250px" }}
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: Scan,
                    }}
                  />
                )}
              </div>
              <div className="Container__right-paragraph">
                {responseState === "failed" ? (
                  <> {t("right.error")}</>
                ) : (
                  <>{t("right.description")}</>
                )}
              </div>
              <div
                className={cs(
                  "Container__right-button",
                  isLoading && "Container__right-button--disabled"
                )}
                onClick={() => {
                  if (responseState === "failed") {
                    window.location.href = window.location.origin;
                  } else {
                    if (!isLoading) {
                      verifyWithBouncer();
                    }
                  }
                }}
              >
                {isLoading ? (
                  <div className="spinner" />
                ) : (
                  <img
                    className="Container__right-button--icon"
                    src={bouncerLogo}
                  />
                )}

                <div className="Container__right-button--label">
                  {responseState === "failed" ? (
                    <>{t("right.button.error")}</>
                  ) : (
                    <>{t("right.button.verify")}</>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {isMessageActive && (
              <div className="Message">
                <Lottie
                  style={{ width: "100px", height: "100px" }}
                  options={{
                    loop: false,
                    autoplay: true,
                    animationData: successLottie,
                  }}
                />
                <div className="Message__title">
                  {t("response.success")} <span>Adults</span>.
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Home;
